var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tutti-dialog", {
    ref: "dialog",
    attrs: {
      "max-width": "800",
      name: "mturk-qualification-create",
      buttons: _vm.buttons,
      overlay: _vm.overlay,
    },
    on: { open: _vm.onDialogOpen },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function ({ on, attrs }) {
          return [
            _c(
              "v-btn",
              _vm._g(
                _vm._b(
                  { attrs: { text: "", color: "indigo" } },
                  "v-btn",
                  attrs,
                  false
                ),
                on
              ),
              [
                _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-plus")]),
                _vm._v(" Create Qualification Type "),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "title",
        fn: function () {
          return [_vm._v(" Create a Qualification ")]
        },
        proxy: true,
      },
      {
        key: "overlay",
        fn: function () {
          return [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: "52", color: "grey" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-card-text",
              [
                _c(
                  "v-form",
                  {
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        "prepend-icon": "mdi-label",
                        label: "Name",
                        rules: [_vm.rules.required],
                      },
                      model: {
                        value: _vm.inputs.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "name", $$v)
                        },
                        expression: "inputs.name",
                      },
                    }),
                    _c("v-text-field", {
                      attrs: {
                        outlined: "",
                        dense: "",
                        "prepend-icon": "mdi-message-text",
                        label: "Description",
                        rules: [
                          _vm.rules.required,
                          _vm.rules.stringShorterThan(2000),
                        ],
                      },
                      model: {
                        value: _vm.inputs.description,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "description", $$v)
                        },
                        expression: "inputs.description",
                      },
                    }),
                    _c("v-switch", {
                      attrs: {
                        dense: "",
                        "hide-details": "",
                        color: "indigo",
                        label: "AutoGranted",
                      },
                      model: {
                        value: _vm.inputs.autoGranted,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "autoGranted", $$v)
                        },
                        expression: "inputs.autoGranted",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }